export {getArtikelanzahl, updateArtikelAnzahl};

const merkzettelArtikelanzahlKey = 'sbe.merkzettel.artikelanzahl';

async function updateArtikelAnzahl() {
    return clearArtikelanzahl();
}

async function getArtikelanzahl() {
    try {
        if (localStorage) {
            if (localStorage[merkzettelArtikelanzahlKey] && localStorage[merkzettelArtikelanzahlKey] !== 'undefined') {
                return localStorage[merkzettelArtikelanzahlKey];
            }
            return fetchArtikelanzahl();
        }
    } catch (storageError) {
    }

    return '';
}

async function fetchArtikelanzahl() {
    let response;
    try {
        response = await fetch('/api/rest/public/merkzettel/admin/artikelanzahl/v2/anzahl.html');
    } catch (fetchError) {
    }

    if (response && response.ok) {
        const artikelanzahl = await response.text();
        localStorage[merkzettelArtikelanzahlKey] = artikelanzahl;
        return artikelanzahl;
    } else {
        clearArtikelanzahl();
        return '';
    }
}

function clearArtikelanzahl() {
    localStorage.removeItem(merkzettelArtikelanzahlKey);
}
