import {getArtikelanzahl, updateArtikelAnzahl} from '../../assets/js/merkzettel-artikelanzahl-client';

class GlobalHeaderMerkzettel extends HTMLElement {

    connectedCallback() {
        this.addEventHandler();
        this.scheduleArtikleanzahlUpdate();
    }

    async getArtikelAufMerkzettel() {
        const anzahl = await getArtikelanzahl();
        this.renderArtikelanzahl(anzahl);
    }

    /**
     * Updates werden nicht direkt ausgeführt, damit verschiedene Events (s. `addEventHandler()`) verarbeitet werden
     * können und nicht mehrfach die Artikelanzahl abgefragt werden. Innerhalb eines Zeitfensters von 300ms wird nur
     * eine Update-Anfrage ausgeführt.
     */
    scheduleArtikleanzahlUpdate() {
        // Wenn es noch ein Update gibt, das wartet, wird nichts gemacht
        if (typeof this.timeoutIdGetArtikelanzahl === 'number') {
            return;
        }
        this.timeoutIdGetArtikelanzahl = window.setTimeout(function () {
            this.getArtikelAufMerkzettel();
            this.timeoutIdGetArtikelanzahl = undefined;
        }.bind(this), 300);
    }

    addEventHandler() {
        window.addEventListener("merkzettel.update", (event) => {
            updateArtikelAnzahl();
            this.scheduleArtikleanzahlUpdate();
        });
        window.addEventListener("login.status", (event) => {
            updateArtikelAnzahl();
            this.scheduleArtikleanzahlUpdate();
        });
        window.addEventListener("merkzettel.clear", (event) => {
            updateArtikelAnzahl();
        });
    }

    renderArtikelanzahl(artikelAnzahl) {
        this.querySelector('[interaction=link-merkzettel]').setAttribute('count', artikelAnzahl);
    }
}

if (!customElements.get("header-global-merkzettel")) {
    customElements.define('header-global-merkzettel', GlobalHeaderMerkzettel);
}
